<!--
  Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
  Unauthorized copying of this file, via any medium is strictly prohibited.
  Proprietary and confidential.
-->

<div class="modal fade" #questionnaireRequestModal tabindex="-1" role="dialog" aria-labelledby="questionnaireRequestModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content block">
      <h5>Request a Company Self-Assessment</h5>

      <form *ngIf="status === null || status === 'Requesting'" class="mt-5" (ngSubmit)="send()" #form="ngForm">
        <div class="m-4">
          <div class="row">
            <div class="col-12">
              <!-- Email -->
              <div class="form-group">
                <label for="qrEmail">Email</label>
                <input id="qrEmail" type="email" class="form-control" name="email" [(ngModel)]="questionnaireRequest.email" [ngModelOptions]="{ updateOn: 'blur' }" required email #email="ngModel" />
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert text-danger">
                  <div *ngIf="email.errors.required">Email is required</div>
                  <div *ngIf="email.errors.email">Email is not valid</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- First Name -->
              <div class="form-group">
                <label for="qrFName">First Name</label>
                <input id="qrFName" type="text" class="form-control" name="fname" [(ngModel)]="questionnaireRequest.fname" required #fname="ngModel" />
                <div *ngIf="fname.invalid && (fname.dirty || fname.touched)" class="alert text-danger">
                  <div *ngIf="fname.errors.required">First Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <!-- Last Name -->
              <div class="form-group">
                <label for="qrLName">Last Name</label>
                <input id="qrLName" type="text" class="form-control" name="lname" [(ngModel)]="questionnaireRequest.lname" required #lname="ngModel" />
                <div *ngIf="lname.invalid && (lname.dirty || lname.touched)" class="alert text-danger">
                  <div *ngIf="lname.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="selectSAQ">
            <div class="col-12">
              <!-- Custom Questionnaire -->
              <div class="form-group">
                <label for="qrQuestionnaire">Questionnaire</label>
                <select id="qrQuestionnaire" name="questionnaire" class="form-control" [(ngModel)]="questionnaireRequest.questionnaire">
                  <option *ngFor="let questionnaire of questionnaires$ | async" [ngValue]="questionnaire">{{ questionnaire.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- Due Date -->
              <div class="form-group">
                <label for="qrDueDate">Due Date</label>
                <input id="qrDueDate" type="date" class="form-control" name="duedate" [(ngModel)]="questionnaireRequest.dueDate" required #dueDate="ngModel" />
                <div *ngIf="dueDate.invalid && (dueDate.dirty || dueDate.touched)" class="alert text-danger">
                  <div *ngIf="dueDate.errors.required">Due Date is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="float-right mt-2">
          <button type="button" class="btn btn-link" (click)="hide()">Cancel</button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid || status === 'Requesting'">{{ status === 'Requesting' ? 'Requesting...' : 'Request' }}</button>
        </div>
      </form>

      <ng-container *ngIf="status === 'Requested'">
        <!-- TODO: model Not sure the sentence below makes sense -->
        <div class="mt-5">Thank you, the company assessment questionnaire has been requested and you can view the progress in the assessment company table.</div>
        <div class="float-right mt-2">
          <button class="btn btn-primary mr-4" class="btn btn-primary" (click)="hide()">Close</button>
        </div>
      </ng-container>

      <ng-container *ngIf="status === 'Error'">
        <div class="mt-5">Unfortunately there was an issue with submitting your company assessment questionnaire request.</div>
        <div>Please contact your client relationship manager or email us at <a href="mailto:support@fairsupply.com.au?subject=Subscription%20to%20Questionnaires">support&#64;fairsupply.com.au</a></div>
        <div class="float-right mt-2">
          <button class="btn btn-primary mr-4" class="btn btn-primary" (click)="hide()">Close</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
