// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, catchError, finalize, first, tap, throwError } from 'rxjs';
import { EntityType } from 'src/app/assessment/assessment.model';
import { ClientService } from 'src/app/client/client.service';
import { QuestionnaireResponse } from 'src/app/questionnaire-response/questionnaire-response.model';
import { QuestionnaireResponseSource } from 'src/app/questionnaire-response/questionnaire-results/saq.model';
import { Questionnaire } from 'src/app/questionnaire/questionnaire.model';
import { MitigationStatus } from '../../company-record/company-record.model';
import { QuestionnaireResponseService } from '../../questionnaire-response/questionnaire-response.service';
import { QuestionnaireRequestService, SaqRequestModalModel } from './questionnaire-request.service';

const makeNewQuestionnaireRequest = () => ({
  email: '',
  fname: '',
  lname: '',
  dueDate: null as string,
  questionnaire: null,
});

@Component({
  selector: 'app-questionnaire-request',
  templateUrl: './questionnaire-request.component.html',
})
export class QuestionnaireRequestComponent {
  @ViewChild('questionnaireRequestModal')
  modal: ElementRef;

  @ViewChild('form')
  form: NgForm;

  status?: 'Requesting' | 'Requested' | 'Error' = null;

  questionnaireRequest = makeNewQuestionnaireRequest();
  questionnaires$: Observable<Questionnaire[]>;

  saqRequestModalModel: SaqRequestModalModel;

  protected selectSAQ = false; // allow the selection of an SAQ to be sent

  constructor(
    private questionnaireResponseService: QuestionnaireResponseService,
    private questionnaireRequestService: QuestionnaireRequestService,
    private clientService: ClientService,
  ) {
    this.questionnaireRequestService.saqRequestModal$.subscribe((saqRequestModalModel: SaqRequestModalModel) => {
      if (!saqRequestModalModel) {
        return;
      }
      this.saqRequestModalModel = saqRequestModalModel;
      this.selectSAQ = this.saqRequestModalModel.client.enabledCustomSaq;
      this.questionnaires$ = this.clientService
        .getQuestionnairesByClient$(this.saqRequestModalModel.client.id, { mode: this.saqRequestModalModel.mode })
        .pipe(
          first(),
          tap(questionnaires => {
            if (questionnaires.length > 0) {
              // Set the default value for the questionnaire
              this.questionnaireRequest.questionnaire = questionnaires[0];
            }
          }),
        );

      this.form?.reset();
      $(this.modal.nativeElement).modal('show');
    });
  }

  send() {
    this.status = 'Requesting';

    if (
      this.saqRequestModalModel.itemType === EntityType.ASSESSMENT ||
      this.saqRequestModalModel.itemType === EntityType.ASSESSMENT_ITEM
    ) {
      this.status = 'Error';
      return;
    }

    this.questionnaireResponseService.showErrors = false;
    const payload: { [key: string]: unknown } = {
      client: this.saqRequestModalModel.client,
      records: [this.saqRequestModalModel.item.id],
      companyName: this.saqRequestModalModel.item.label,
      mode: this.saqRequestModalModel.mode,
      recipients: [
        {
          firstName: this.questionnaireRequest.fname,
          lastName: this.questionnaireRequest.lname,
          email: this.questionnaireRequest.email,
          username: null,
          isStaff: false,
        },
      ],
      dueOn: this.questionnaireRequest.dueDate,
      source: QuestionnaireResponseSource.LIVE_ASSESSMENT,
    };

    // if sending a custom saq add the questionnaire to the payload
    if (this.questionnaireRequest.questionnaire) {
      payload.questionnaire = this.questionnaireRequest.questionnaire;
    }

    this.questionnaireResponseService
      .addOne$(payload)
      .pipe(
        first(),
        tap(() => {
          this.status = MitigationStatus.REQUESTED;
          this.saqRequestModalModel.item.mitigationStatus = 'REQUESTED';
        }),
        tap((questionnaireResponse: QuestionnaireResponse) => {
          this.questionnaireRequestService.saqRequestSent({ item: this.saqRequestModalModel.item });
        }),
        catchError(err => {
          this.status = 'Error';
          return throwError(() => err);
        }),
        finalize(() => {
          this.questionnaireResponseService.showErrors = true;
        }),
      )
      .subscribe();
  }

  protected hide() {
    this.status = null;
    this.questionnaireRequest = makeNewQuestionnaireRequest();
    $(this.modal.nativeElement).modal('hide');
  }
}
